import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma de consola, semicircular, envoltant el tronc damunt el qual viu. Pot arribar a mesurar uns 10-12 cm de diàmetre. La part superior està formada per un conjunt de semicercles concèntrics de color obscur amb molses que han crescut al damunt. A la part inferior hi ha l’himeni amb tubs en capes i porus arrodonits de color marró groguenc a canyella. Les espores són de rodones a ovalades, de 3-4 x 2,5-3 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      